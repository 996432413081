import React from "react"
//import { Link, graphql } from "gatsby"
import './index.css'


import SEO from "../components/seo"
import { Link } from "gatsby"


class BlogIndex extends React.Component {
  render() {



 
    return (
      <>
        <SEO title="About" />
        <h1>About</h1>
        
        <div style={{display:"flex", flexDirection:"row-reverse", width:"100%"}}>
        <div>
        <img src="/images/hena.jpg" alt="Photo"/>
        </div>
    
        </div>

        <p>
        Hello! This site is run by Henrik Tünnermann. I am an assistant professor at <a href="https://ils.uec.ac.jp">Institute for Laser Science</a>, 
        <a href="https://www.uec.ac.jp">The University of Electro-Communications</a>. You will find a collection articles featuring the follwing topics here:
        </p>

        <ul>
          <li>Laser Science</li>
          <li>Single Frequency Lasers</li>
          <li>Fiber Lasers</li>
          <li>Noise</li>
          <li>Mode Locked Lasers</li>
          <li>Coherent Beam Combining</li>
          <li>Artificil Intelligence</li>
          <li>Scientific Computing</li>
        </ul>

        While those are not peer reviewed <Link to="pub">research articles</Link>, I hope they will be able to provide some additional background to the articles
        I have published or be just generally interesting.
        
      <p>

      </p>

<h3>
You can follow or contact me on social media as well
</h3>
<div className="socialgrid">
<p><img src="/icons/envelope.svg"  style={{width:"12px", height:"12px"}} alt="mail"/>
<a href="https://github.com/HTuennermann"> tuennermann@gmail.com</a></p>

<p><img src="/images/linkedin.png"  style={{width:"12px", height:"12px", margin:0}} alt="linkedin"/>
<a href="https://www.linkedin.com/in/tuennermann/"> linkedin.com/in/tuennermann</a></p>

<p><img src="/images/facebook.png"  style={{width:"12px", height:"12px"}} alt="facebook"/>
<a href="https://www.facebook.com/tuennermann"> facebook.com/tuennermann</a></p>

<p><img src="/images/twitter.png"  style={{width:"12px", height:"12px"}} alt="twitter"/>
<a href="https://twitter.com/HTuennermann"> twitter.com/HTuennermann</a></p>

<p><img src="/images/github.png"  style={{width:"12px", height:"12px"}} alt="github"/>
<a href="https://github.com/HTuennermann"> github.com/HTuennermann</a></p>
</div>


      </>
    )
  }
}

export default BlogIndex
